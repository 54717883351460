.quiz-intro, .quiz-ok, .quiz-fail {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-width: 768px;
  padding: 0 0 3em;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (orientation: portrait) {
    background-size: cover;
  }

  p {
    font-size: 1.4em;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.85);
  }
}

.quiz-intro {
  background-image: url("../images/intro.jpeg?as=jpeg");
}

.quiz-ok {
  background-image: url("../images/ok.jpeg?as=jpeg");
}

.quiz-fail {
  background-image: url("../images/fail.jpeg?as=jpeg");
}
