.question {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 768px;
  padding: 1em 0;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (orientation: portrait) {
    background-size: cover;
  }

  h2 {
    margin: 0;
    text-align: center;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.85);
    font-size: 1.5em;

    strong {
      display: block;
      font-size: 1.5em;
    }
  }

  .question-buttons {
    align-self: stretch;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;

    button {
      display: block;
      appearance: none;
      width: 150px;
      height: 150px;
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  .question-trash {
    background: url("../images/trash.png?width=300") center center / contain no-repeat;
  }

  .question-return {
    background: url("../images/return.png?width=300") center center / contain no-repeat;
  }

  .question-answer {
    margin: auto 0 0.5em;
    font-size: 3em;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.85);
  }

  .question-next {
    margin-bottom: 1em;
  }
}
