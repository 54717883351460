@font-face {
  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/sourcesanspro-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/sourcesanspro-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/sourcecodepro-regular.woff2') format('woff2');
}


*, *::before, *::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font: 13px/1.5 'Source Sans Pro', sans-serif;
  color: #333;
  background: #eee;
}

#root {
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  color: #eee;
}

button {
  padding: 0.25em 1em;
  border: none;
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.85);
  font: inherit;
  font-size: 1.5em;
  cursor: pointer;
}
