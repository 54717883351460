.question {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 768px;
  height: 100%;
  padding: 1em 0;
  display: flex;
}

@media (orientation: portrait) {
  .question {
    background-size: cover;
  }
}

.question h2 {
  text-align: center;
  text-shadow: 0 0 5px #000000d9;
  margin: 0;
  font-size: 1.5em;
}

.question h2 strong {
  font-size: 1.5em;
  display: block;
}

.question .question-buttons {
  flex-flow: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: baseline;
  display: flex;
}

.question .question-buttons button {
  appearance: none;
  border: none;
  width: 150px;
  height: 150px;
  margin: 0;
  padding: 0;
  display: block;
}

.question .question-trash {
  background: url("images/trash.0e26878d.png") center / contain no-repeat;
}

.question .question-return {
  background: url("images/return.687b4603.png") center / contain no-repeat;
}

.question .question-answer {
  text-shadow: 0 0 5px #000000d9;
  margin: auto 0 .5em;
  font-size: 3em;
}

.question .question-next {
  margin-bottom: 1em;
}

.quiz-intro, .quiz-ok, .quiz-fail {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 768px;
  height: 100%;
  padding: 0 0 3em;
  display: flex;
}

@media (orientation: portrait) {
  .quiz-intro, .quiz-ok, .quiz-fail {
    background-size: cover;
  }
}

.quiz-intro p, .quiz-ok p, .quiz-fail p {
  text-shadow: 0 0 5px #000000d9;
  font-size: 1.4em;
}

.quiz-intro {
  background-image: url("images/intro.2afc370e.jpeg");
}

.quiz-ok {
  background-image: url("images/ok.b273ca1f.jpeg");
}

.quiz-fail {
  background-image: url("images/fail.3a8628b2.jpeg");
}

@font-face {
  font-family: Source Sans Pro;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("fonts/sourcesanspro-regular.b9d43715.woff2") format("woff2");
}

@font-face {
  font-family: Source Sans Pro;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("fonts/sourcesanspro-bold.98fe236b.woff2") format("woff2");
}

@font-face {
  font-family: Source Code Pro;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("fonts/sourcecodepro-regular.a434fc4a.woff2") format("woff2");
}

*, :before, :after {
  box-sizing: border-box;
}

body, html {
  color: #333;
  background: #eee;
  margin: 0;
  padding: 0;
  font: 13px / 1.5 Source Sans Pro, sans-serif;
}

#root {
  color: #eee;
  background: #222;
  justify-content: center;
  align-items: center;
  width: 100svw;
  height: 100svh;
  display: flex;
}

button {
  font: inherit;
  cursor: pointer;
  background: #ffffffd9;
  border: none;
  border-radius: .5em;
  padding: .25em 1em;
  font-size: 1.5em;
}

/*# sourceMappingURL=index.c937a3f0.css.map */
